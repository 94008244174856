/* .react-pagination {
  background: black;
} */
/* 
.react-pagination {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */
.react-pagination {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 15px 0;
}

.react-pagination li {
    display: inline-block;

}

.react-pagination li a {
    padding: 6px 8px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 8px;
    margin-left: 0;
    margin-right: 8px;
    border: 1px solid #fff;
    font-size: 12px;
    font-weight: 500;
    color: #8A93A6;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.react-pagination li a {
    background-color: #ebf2fd;
    color: #5a8dee;
}

.react-pagination li:first-child a, .react-pagination li:last-child a {
    background-color: #F6F7FB;
}

.react-pagination li.selected a {
    background-color: #0e539f;
    border-color: #5a8dee;
    color: #fff;
}

.filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.table tbody td {
    max-height: 50px;
    height: 50px;
    min-height: 50px;
    padding: 0 5px;
    text-align: left;
}

.table-action {
    cursor: pointer;
}

.table-action-container {
    display: flex;
    justify-content: space-evenly;
}

tr.edit_mode > td {
    max-height: 70px;
    height: 70px;
}

td img{
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.table-container {
    flex: 1;
    overflow: auto;
}

.main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: auto;
}

.table_error_message {
   color: red;
    font-size: 12px;
}

.disable-icon {
    cursor: not-allowed;
    pointer-events: none;
}

.table-footer {
    padding: 0 10px;
    align-items: center;
    display: flex;
    border-top: 1px solid #dee2e6;
    justify-content: flex-end;
}