.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*font cdn*/
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  font-family: 'Inter', sans-serif !important;
}
/*homepage*/

.homepage-container {
  font-family: 'Inter', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.homepage-title {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #27272e;
  margin: 12px 0px;
}
@media only screen and (max-width: 450px) {
  .homepage-title,
  .homepage-account-creation-link,
  .homepage-button,
  .registration-page-title,
  .registration-page-subTitle,
  .label,
  .input,
  .registration-button,
  .registration-form,
  .registerDevice-container,
  .serial-number-info,
  .complete-onboading-subtext,
  .complete-onboading-button,
  .reset-password-form,
  .login-with-google-btn,
  .login-with-apple-btn,
  .errorpage-account-creation-link,
  .errorpage-button,
  .serial-number-info-container {
    width: 280px !important;
  }
  .serial-number-select-list-style {
    width: 120px !important;
  }
  .serial-number-input {
    width: 170px !important;
  }
  .stepper-conatiner {
    padding-right: 3rem !important;
  }
  .serialNumber-container {
    margin-left: 0 !important;
  }
}
.homepage-account-creation-link {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
  color: #27272e;
  margin: 12px 0px;
}
.homepage-button {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  background: #153a6f;
  border-radius: 8px;
  padding: 16px 24px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}
.homepage-other-accounts,
.registration-other-accounts {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #718096;
  margin: 30px 24px;
  text-align: center;
}

/*registration-page*/
.installSoftware-container,
.registrationDevice-container,
.registrationAddDevice-container,
.signup-container,
.allowPermissons-container,
.password-reset-container {
  font-family: 'Inter', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 4rem auto;
  height: 100vh;
}
.registrationDevice-container,
.registrationAddDevice-container {
  margin: 0px !important;
}
.signup-container {
  height: unset !important;
  margin: 0px auto !important;
}
.password-confirm-container {
  font-family: 'Inter', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.registrationAddDevice-container {
  margin: 0rem auto !important;
  height: 84vh !important;
}
.registration-container {
  font-family: 'Inter', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.registration-page-title {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #27272e;
  margin: 12px 0px 0px;
}
.extension-title {
  width: 450px !important;
}
.registration-page-subTitle,
.installSoftware-page-subTitle {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #27272e;
  margin: 0px 0px 12px;
}
.form-details {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.label {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #425466;
}
.input {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  height: 46px;
  background: #edf2f7;
  border-radius: 6px;
  border: none;
  padding-left: 16px;
}

.input::placeholder {
  color: #7a828a;
}
.password-warning {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: #718096;
  margin-top: 8px;
  margin-bottom: 0px;
}
.form-checks {
  display: flex;
  margin-top: 30px;
}
.checkbox-style {
  width: 20px;
  height: 20px;
}
.checkbox-label {
  width: 395px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #425466;
  text-align: center;
}
.registration-button {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  background: #153a6f;
  border-radius: 8px;
  padding: 16px 24px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  margin-top: 30px;
}
.accounts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}
.google-logo,
.apple-logo,
.facebook-logo {
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2),
    0px 1px 3px rgba(50, 50, 71, 0.1);
  border-radius: 8px;
  padding: 15px 26px;
  cursor: pointer;
}
.serialNumber-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  height: 50px !important;
}
.installSoftware-page-subTitle {
  line-height: 27px !important;
  padding: 0px 75px !important;
}

.allowPermissons-container {
  margin: 0px auto !important;
  height: 75vh !important;
}
.password-reset-container {
  margin: 0px auto !important;
  height: 83vh !important;
}
.installSoftware-container {
  margin: 0px !important;
  height: 74vh !important;
}

.waving-hand-emoji {
  font-size: 32px;
  text-align: center;
}

.navbar-conatiner-style {
  display: flex !important;
  justify-content: space-between !important;
  border-bottom: none !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.nav-list-item {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
.nav-list-item:first-child {
  font-weight: 500;
  color: #153a6f !important;
}

/*Stepper UI*/

.timeline {
  position: relative;
  z-index: 1;
}

.timeline li {
  list-style: none;
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-family: 'Inter', sans-serif !important;
}

ul:nth-child(1) {
  color: #153a6f;
}

.timeline li:before {
  content: '';
  width: 30px;
  height: 30px;
  border: 5px solid #153a6f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  background: #fff;
  color: #000;
  transition: all ease-in-out 0.3s;
}
.timeline li.fill-item:before {
  background-color: #153a6f;
}
.timeline li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #153a6f;
  top: 25px;
  left: -50%;
  z-index: -999;
  transition: all ease-in-out 0.3s;
}

.timeline li:first-child:after {
  content: none;
}
.timeline li.active-tl {
  color: #153a6f;
  font-weight: 600;
  font-family: 'Inter', sans-serif !important;
}
.timeline li.active-tl:before {
  background: #153a6f;
  color: #f1f1f1;
  box-shadow: 0px 0px 10px 1px rgb(21 58 111 / 75%);
}

.timeline li.active-tl + li:after {
  background: #153a6f;
}
.login-button-link {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 5rem;
}
.login-button-link > a {
  font-family: 'Inter', sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  cursor: pointer;
}
.forgot-password-button-link {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}
.forgot-password-button-link > a {
  font-family: 'Inter', sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
}
.homepage-company-logo-style {
  width: 150px;
}
/*Dashboard*/

.dashboard-title {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #27272e;
  margin: 0px;
}
.device-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c6c9ce;
  height: 200px;
  padding: 10px;
  border-radius: 8px;
  gap: 20px;
}
.device-details:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.solid {
  border-top: 1px solid #bbb;
}
.device-name {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #27272e;
}
.serial-number {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #27272e;
}
.device-info-wrapper {
  text-align: left;
}
.button-vertical-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.device-img-style {
  width: 130px;
  height: 100px;
}
.add-device-button {
  font-family: 'Inter', sans-serif !important;
  width: 175px;
  background: #153a6f;
  border-radius: 8px;
  padding: 16px 24px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}
.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
}

.stepper-conatiner {
  width: 100%;
  padding-left: 7.5rem;
  padding-right: 9.5rem;
  margin-bottom: 1.5accourem;
  margin-top: 3rem;
}
@media only screen and (max-width: 991px) {
  .stepper-conatiner {
    padding-left: 0rem !important;
    padding-right: 1.5rem !important;
  }
  .navitem-small-screen-style {
    width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 700px) {
  .stepper-conatiner {
    padding-left: 0rem !important;
  }
}
@media only screen and (max-width: 500px) {
  .stepper-conatiner > .timeline {
    display: none !important;
  }
}
@media only screen and (min-width: 500px) {
  .small-screen-stepper,
  .show-small-screen {
    display: none !important;
  }
}
.small-screen-stepper {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #153a6f;
  text-align: center;
}
.small-screen-menu {
  border-bottom: 3px solid #153a6f;
  padding-bottom: 4px;
}
.serial-number-info {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #27272e;
  margin-top: 0px;
  margin-bottom: 0px;
}
.serial-number-input {
  font-family: 'Inter', sans-serif !important;
  width: 229px;
  height: 46px;
  background: #edf2f7;
  border-radius: 6px;
  border: none;
  padding-left: 16px;
  margin-left: 16px;
}
.serial-number-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.serial-number-select-list-style {
  background: #edf2f7;
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 0px 10px;
  height: 46px;
}

.complete-onboarding-container {
  font-family: 'Inter', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 65vh;
}
.complete-onboading-message {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #27272e;
}
.complete-onboading-subtext {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #27272e;
}
.complete-onboading-button {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  background: #153a6f;
  border-radius: 8px;
  padding: 16px 24px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}
.complete-onboarding-wrapper {
  text-align: center;
}
.firefox-extension-container {
  font-family: 'Inter', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
}
.extension-buttons-container {
}
.extension-card {
  text-align: center;
  border: 1px solid rgb(202, 202, 202);
  width: 250px;
  height: 80px;
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 8px;
}
.extension-card > img {
  width: 48px;
}
.extension-card:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.company-logo-style {
  width: 125px !important;
  height: 80px !important;
  max-width: unset !important;
  max-height: unset !important;
}
.dropdown-menu-style {
  width: 100%;
  text-align: end;
}
.dropdown > button {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  padding: 0px !important;
}
.device-img-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}
.dashboard-subtitle {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  color: #27272e;
  margin: 0px 0px 12px;
}
.errorpage-container {
  font-family: 'Inter', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  height: 77vh;
}
.errorpage-button {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  background: #153a6f;
  border-radius: 8px;
  padding: 16px 24px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  margin-top: 10px;
}
.errorpage-account-creation-link {
  font-family: 'Inter', sans-serif !important;
  width: 425px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #27272e;
  margin: 16px 0px 12px;
}
.account-creation-link {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 12px;
  color: #718096;
  margin: 30px 24px;
  text-align: center;
}
.register-button-link {
  text-align: center;
  margin-top: 2rem;
}
.register-button-link > a {
  font-family: 'Inter', sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  cursor: pointer;
}
.drop-down-menu-styling {
  top: 10px !important;
  left: -15px !important;
  transform: translate3d(-120px, 24px, 0px) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.logout-wrapper {
  position: absolute;
  top: 35px;
  right: 0px;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0 0 0;
  font-size: 0.9375rem;
  color: #12263f;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(18, 38, 63, 0.1);
  border-radius: 0.375rem;
}
.logout-wrapper-item {
  font-weight: 400;
  color: #6e84a3;
  font-size: 15px;
  padding: 0.375rem 1.5rem;
}
@media only screen and (max-width: 991px) {
  .logout-wrapper {
    margin-left: 0px;
  }
}
.logout-container {
  position: relative;
}
.login-with-google-btn,
.login-with-apple-btn {
  width: 425px;
  border: 1px solid #98a6ad;
  height: 45px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #edf2f7;
}
.login-with-apple-btn {
  margin-top: 1rem;
}

.login-with-google-btn > img,
.login-with-apple-btn > img {
  width: 18px;
}

.login-with-google-btn:hover,
.login-with-apple-btn:hover {
  box-shadow: 0px 0px 4px 1px rgb(21 58 111 / 50%);
}
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  content: '';
  background: #b9d5fb;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #212529;
  background-color: #fcfcfa;
}
.register-account-company-logo-style {
  width: 120px;
  margin-top: 3rem;
}
.login-error-text {
  font-family: 'Inter', sans-serif !important;
  width: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: red;
  margin: 12px 0px 0px;
}
.user-avatar {
  border-radius: 50%;
  width: 42px;
}
.user-name {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #27272e;
}
.user-email {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #27272e;
}

.toast-success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
}

.truefire-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  margin-top: 10px;
}

.extension-icon-style {
  height: 60px;
  margin: 20px;
}

.extension-device-container {
  display: grid;
  grid-template-columns: 155px 155px 155px;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  margin-top: 10px;
}

.extension-device-style {
  height: 60px;
}

.software-device-container {
  gap: unset !important;
}

.software-device-header {
  margin: 20px 0px !important;
}

.software-name {
  font-weight: bold;
  margin-top: 10px;
}
.input-container {
  position: relative;
}
@media only screen and (max-width: 450px) {
  .extension-device-container {
    grid-template-rows: 1fr 1fr 1fr !important;
    grid-template-columns: unset !important;
  }
  .device-details-card-height {
    height: 460px !important;
  }
}
.serial-number-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.registerDevice-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.serial-number-info-container {
  width: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
}
